.location-how-we {
  background-color: #000;
}

.location-how-we-main {
  max-width: 1200px;
  margin: auto;
  padding: 60px 20px;
}
.location-how-we-heading {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
  margin: 30px 0;
}
.location-how-we-heading > span {
  color: #f00;
}
.location-how-we-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  gap: 30px;
}

.location-how-we-col {
  background: #fff;
  color: #000;
  text-align: center;
  border-radius: 40px;
  padding: 20px 40px;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
    inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
  /* box-shadow: 4px 4px 6px 0 #ffffff80,-4px -4px 6px 0 #ffffff36,inset -4px -4px 6px 0 #3e3e3e33,inset 4px 4px 6px 2px hsla(0,0%,43%,.201); */
  /* margin-top: 80px; */
}

.location-how-we-col-img {
  width: 130px;
  margin: auto;
  /* margin-top: -90px; */
}
.location-how-we-col-img > img {
  width: 100%;
}
.location-how-we-col-heading {
  font-size: 1.2rem;
  color: #f00;
  font-weight: 600;
}

.location-how-we-col {
  display: flex;
  padding: 30px 20px;
  gap: 20px;
}

.location-how-we-col-content {
  flex: 1;
  text-align: left;
}

.location-how-we-col-img {
  width: 70px;
}

@media only screen and (max-width: 1000px) {
  .location-how-we-heading {
    font-size: 1.8rem;
    margin: 10px 20px;
  }
  .location-how-we-content {
      grid-template-columns: 1fr;
    }
    .location-how-we-col{
        padding: 20px;
    }
}
