.faq-comp{
    max-width: 1200px;
    margin:  auto;
    padding: 60px 0;
}
.faq-comp-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.faq-comp-head>span{
    width: 130px;
    text-align: end;
    /* text-decoration: underline; */
    color: #ff2222;
    font-size: 1.3rem;
    display: flex;
    justify-content: flex-end;

}

.faq-comp-head>span>i{
    border: solid 2px;
    /* padding: 5px 10px; */
    font-size: 1.7rem;
    display: flex;
    width: 10px;
    justify-content: center;
    align-items: center;
    width : 40px;
    height: 40px;
    cursor: pointer;
}

.faq-comp-head-heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
    /* color: #ff0000; */
    margin-bottom: 10px;
}

.faq-comp-head-heading>span{
    color: #ff0000;
}

.faq-comp-heading-content-ques{
    font-size: 1.1rem;
    font-weight: 700;
    margin-top: 20px;
}
.faq-comp-heading-content-ans{
    /* font-size: .9rem; */
}
.faq-comp-heading-content-ans>span{
    font-weight: 700;
    font-size: 1.1rem;
}
.faq-comp-heading-content-span{
    text-align: center;
   
    /* display: flex;
    justify-content: center; */
    color: #ff0000;
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: underline;
}


@media only screen and (max-width: 600px) {
    .faq-comp{
        /* margin:  80px 20px; */
        padding: 60px 20px;
    }
    .faq-comp-head{
        /* display: grid; */
        text-align: center;
        grid-template-columns: repeat(1, 1fr);
    }
    .faq-comp-head>span{
        width: auto;
        text-align: center;
    }
    .faq-comp-head>span:first-child{
        display: none;
    }
        
    .faq-comp-head-heading{
        font-size: 1.8rem;
    }
    
    .faq-comp-heading-content-ques{
        font-size: 1rem;
        font-weight: 700;
        margin-top: 20px;
    }
    .faq-comp-heading-content-ans{
        /* font-size: .8rem; */
    }
    .faq-comp-heading-content-ans>span{
        font-weight: 700;
        font-size: 1rem;
    }
    
       
}