.header-top a {
  color: white;
  text-decoration: none;
}
.header-top a:hover {
  text-decoration: underline;
}
.header-top {
  background-color: #d30000;
  color: white;
  /* padding: 0 30px 0 270px; */
}
.header-top > div {
  padding: 0 50px 0 270px;
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top-col {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.9rem;
  padding: 10px;
}
.header-top-col:nth-child(1) {
  gap: 30px;
}

.header-top-col-icon {
  background-color: white;
  color: #d30000 !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.header-top-col-icon > i {
  display: flex;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .header-top > div {
    padding: 0 50px 0 0px;
  }

  .header-top-col-mail {
      /* display: none; */
    }
    .header-top-col{
        justify-content: start;
        padding: 10px 0;
    }
    
  .header-top-col:nth-child(1) {
    font-size: 0.8rem;
    margin: auto;
    gap: 10px;
    flex-wrap: wrap;
    /* justify-content: center; */
    padding-left: 140px;
  }
  .header-top-col:nth-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
.header-top {
    height: 50px;
    display: flex;
    align-items: center;
    /* justify-content: start; */
}
.header-top >div{
    /* display: none; */
}

.header-nav-logo{
  padding: 5px 8px;
}
.header-nav-logo>img{
  width: 90px;
}

.header-top-col:nth-child(1){
  padding-left: 110px;
}

.header-top-col > a:first-child{
  /* color: aqua; */
  display: none;
}

.header-nav-menu-button{
  top: 10px;
  right: 10px;
}
    
}
