.category-component{
    max-width: 1300px;
    margin: 0px auto;
    padding: 60px 0;
}
.category-component-heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
    
}
.category-component-heading>span{
    font-weight: 600;
    color: #ff0000;
}

.category-component-head-text{
    text-align: center;
    color: rgb(169, 169, 169);
    max-width: 900px;
    margin: 0 auto 10px;
    font-weight: 400;
}

.category-component-content{
    /* max-width: 1500px;
    margin: auto; */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    /* gap: 80px 30px; */
    /* padding: 50px 20px;
    padding-top: 20px; */
    /* background-color: black; */
}


.category-component-col{
    background-color: rgb(0, 0, 0);
    background-color: gray;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
    box-shadow: rgba(255, 255, 255, 0.25) 0px 0px 10px;
    color: rgb(221, 221, 221);
    color: red;
    color: black;
    box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
    border-radius: 20px;   
    margin: 25px 15px;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
}
.category-component-col:hover{
    /* margin: 0; */
    /* padding: 10px; */
    margin: 15px  5px;
    transition: .5s ease;
    font-size: 1.1rem;
    /* padding: 0px 10px; */
}

.category-component-col-img>img{
    width: 100%;
    /* padding: 0 20px; */
    width: 60%;

}

@media only screen and (max-width: 800px) {

    .category-component{
        margin: 0px auto;
  padding: 40px 20px;

        
    }
    .category-component-heading{
        font-size: 2rem;  

    }

.category-component-head-text{
    font-size: .9rem;
}
    
    .category-component-content{
        grid-template-columns: repeat(2, 1fr);        
        padding: 0px ;
        /* margin: 0 20px ; */
    }
    
    .category-component-col{
        padding: 20px 10px;
        border-radius: 20px;   
        margin: 15px 15px;
        font-size: .8rem;
    }
    .category-component-col:hover{
        margin: 10px  10px;
        font-size: 1rem;
    }
    

}
