.location-faq-main {
  max-width: 1330px;
  margin: 60px auto;
  padding: 0 15px;
}
.location-faq-heading {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
  margin: 30px 0;
}

.location-faq-heading > span {
  color: #f00;
}

.location-faq-col {
  background-color: rgb(0, 0, 0);
  box-shadow: 2px 2px 5px #fff3;
  margin-bottom: 25px;
  padding: 20px;
}

.location-faq-question {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.location-faq-question > span > span {
  color: #f00;
  font-weight: 600;
}

.location-faq-question > i {
  color: #f00;
  margin-top: 5px;
  font-size: 1.4rem;
  cursor: pointer;
}

.location-faq-answer {
  margin-top: 10px;
}

.location-faq-answer > span {
  color: #f00;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .location-faq-main {
    margin: 40px auto;
  }
  .location-faq-heading {
    font-size: 1.8rem;
    margin: 10px 20px;
  }

  .location-faq-col {
    padding: 10px;
  }
  
.location-faq-question {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .location-faq-question > i {
      font-size: 1.2rem;
    }
    .location-faq-answer{
        font-size: .85rem;
    }
}
