.error-page{
    text-align: center;
    padding: 100px 50px 150px;
    
  }

.error-page-heading-1{
    font-size: 3rem;
    font-weight: 500;
}
.error-page-heading-2{
    font-size: 1.1rem;
    padding-bottom: 80px;
    font-weight: 400;
}
.error-page-heading-3{
    font-size: 1.2rem;
    background: red;
    color: white;
    text-decoration: none;
    padding: 10px 50px;
    font-weight: 500;
}

.error-page-heading-3:hover{
    background-color: white;
}

.error-page-links{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .error-page{
        text-align: center;
        padding: 100px 10px 120px ; 
      }
      
.error-page-heading-1{
    font-size: 1.4rem;
}
      
.error-page-heading-2{
    font-size: .8rem;
    padding-bottom: 40px;
}

.error-page-heading-3{
    padding: 5px 30px;
    font-size: 1rem;
}
      
      
}

    