.header-nav-content {
  background-color: black;
  padding: 30px;
}
.header-nav-content-main {
  max-width: 1400px;
  margin: auto;
}

.header-nav-logo{
    position: absolute;
    top: 0;
    background-color: white;
    padding: 15px 25px 15px;
    border-radius: 0 0 30px 30px  ;
}

.header-nav-logo>img{
    width: 130px;
}

.header-nav-menu >div{
  display: flex;
  gap: 40px;
  /* padding: 20px; */
  padding-left: 230px;
}

.header-nav-menu a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.header-nav-menu a:hover{
    color: red;
}

.header-nav-menu-red{
    color: red !important;
}

.header-nav-menu-drop>span{
    cursor: grabbing;
}
.header-nav-menu-drop-content {
  display: none;
}

.header-nav-menu-drop:hover 
.header-nav-menu-drop-content {
  display: block;
}
.header-nav-menu-drop-content {
  position: absolute;
  text-align: center;
  background-color: black;
  padding: 10px 20px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
  z-index: 1;
}

.header-nav-menu-button {
  display: none;
}

@media only screen and (max-width: 800px) {

    .header-top{
        /* display: none; */
    }

    /* -------------------------------------------- */

    .header-nav-menu-button >i{
        display: flex;
    }
    .header-nav-menu-button {
        display: block;
        font-size: 1.2rem;
        position: absolute;
        top: 20px;
        right: 20px;
        /* background-color: black; */
        background-color: rgb(255, 255, 255);
        color: red;
        padding: 5px 8px;
    }
    
.header-nav-content {
    padding: 0px;
}

.header-nav-content-main {
    display: flex;
    justify-content: flex-end;
}
   
    .header-nav-logo{
        position: absolute;
        top: 0;
        left: 10px;
        background-color: white;
        padding: 10px 10px 10px;
        padding: 10px 8px ;
        border-radius: 0 0 20px 20px  ;
    /* display: none; */
    }
    .header-nav-logo>img{
        width: 100px;
    }
    
    
.header-nav-menu {
    justify-content: end;
    }
    
    .header-nav-men{
        margin: 0px -20px;
        padding: 0;
        position: fixed;
        bottom: 100vh;
        height: 100%;
        width: 102%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: var(--mainColor);
        transition: 1s;
    }
    .header-nav-menu{
        display: none;
    }
    
    .header-nav-menu-active{
        background-color: rgba(0, 0, 0, 0.767);
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 0;
        gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
    
    .header-nav-menu-active>div{
        gap: 20px;
        display: flex;
        width: 80%;    
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding: 0;
        box-shadow: rgba(102, 102, 102, 0.555) -1px 0px 9px;
    }

    .header-nav-menu-button-close{
        position: fixed;
        top:   20px;
        right: 20px;
        font-size: 2rem;
        background-color: transparent;
        color: white;
        
    }
    
    
}

