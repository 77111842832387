.our-benefits{
    max-width: 1300px;
    margin: 0px auto;
    padding: 60px 0;
}   

.our-benefits-heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
    
}
.our-benefits-heading>span{
    font-weight: 600;
    color: #ff0000;
}

.our-benefits-content{
    margin: 40px 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;
    align-items: center;
}

.our-benefits-col-1-heading{
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.1;
    margin: 15px 0;
}
.our-benefits-col-1-heading>span{
    color: #ff0000;
}


/* ------------------------------------------------------------ */

.our-benefits-col-2-col-icon>img{
    /* height: 70px; */
    max-height: 70px;
    max-width: 65px;
}


.our-benefits-col-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.our-benefits-col-2-col{
    background-color: black;
    background-color: white;
    color: black;
    box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);

    border-radius: 20px;
    padding: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.our-benefits-col-2-col-heading{
    font-size: 1.3rem;
    line-height: 1.3;
    margin-bottom: 5px;
    font-weight: 600;
}

.our-benefits-col-2-col-text{
    font-size: .89rem;
}

.our-benefits-col-1-text{
    font-size: 1.05rem;
    font-weight: 400;
}


@media only screen and (max-width: 800px) {
    .our-benefits{
        /* margin: 40px 20px; */
        padding: 40px 20px;
    }   
    
    .our-benefits-heading{
        font-size: 2rem;        
    }
    .our-benefits-content{
        margin: 20px 0px;
        display: grid;
        grid-template-columns: 1fr ;
        gap: 20px;
        align-items: center;
    }
    
    .our-benefits-col-1{
        text-align: center;
    }
    .our-benefits-col-1-heading{
        font-size: 1.2rem;
        margin: 10px 0;
    }
    .our-benefits-col-1-text{
        font-size: .9rem;
    }
    
    /* ------------------------------------------------------------ */
    
    .our-benefits-col-2-col-icon>img{
        max-height: 60px;
        max-width: 60px;
    }
    
    
    .our-benefits-col-2{
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    
    .our-benefits-col-2-col{
        padding: 15px 10px;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
    .our-benefits-col-2-col-heading{
        font-size: 1.1rem;
        line-height: 1.3;
        margin-bottom: 5px;
        font-weight: 600;
    }
    
    .our-benefits-col-2-col-text{
        font-size: .8rem;
    }
    
    
    
}


