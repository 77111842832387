/* --------------- */

.select-btn div a{
  color: white;
  text-decoration: none;
}

/* --------------- */

.goc-logo img{
  width: 230px;
  /* padding: 10px; */
  display: flex;
}


.nav-bar{
  background-color: #000001;
}

.head-main{
  background-color: black;
}
.head-main-logo{
  background-color: white;
  position: absolute;
  top: 0;
  padding: 20px 15px 20px;
  border-radius: 0 0 40px 40px  ;
}
.head-main-logo>img{
  width: 150px;
}



header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 30px;
  color: var(--textColor);
  max-width: 1400px;
  margin: auto;
}
header a {
  text-decoration: none;
  color: white;
}
.nav-logo {
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  font-family: "Poppins";
}
.nav-logo span {
  color: red;
}
.nav-logo i {
  font-size: 1.8rem;
}
.nav-end {
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
}

.nav-btn {
  display: none;
}
nav a,
nav div {
  font-size: 14px;
  color: var(--textColor);
  text-decoration: none;
  margin-right: 45px;
}

nav div span:hover {
  color: red;
  /* cursor: pointer; */
  cursor: grab;
}

.nav-drop {
  position: relative;
  z-index: 1;
}

.nav-drop-content {
  display: none;
  position: absolute;
  /* margin-top: 5px; */
  padding: 10px;
  background-color: #000001;
  /* width: auto; */
  width: 8rem;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;
}

.nav-drop:hover .nav-drop-content {
  display: block;
}

.nav-d-c-link {
  display: grid;
  gap: 10px;
  margin-right: 0;
}
.nav-d-c-link a{
  margin-right: 0;
}

.nav-end-start {
  padding-right: 30px;
  line-height: 40px;
  border-right: rgb(122, 122, 122) 2px solid;
}
.nav-end-start > .sd {
  display: none;
}

.nav-end-end > .sd {
  display: none;
}

.nav-end-end {
  padding-left: 40px;
}
.nav-end-end i {
  margin-right: 15px;
  font-size: larger;
}

/* a:hover {
  color: red;
} */

nav {
  display: flex;
  align-items: center;
  text-align: center;
  /* padding-right: 40px; */
  padding-left: 250px;
}

/* .nav-select {
  background-color: rgb(33, 33, 33);
  border: none;
  color: white;
  border-radius: 10px;
  width: auto;
  text-align: center;
  padding: 3px 8px;
  font-size: larger;
  font-weight: bold;
} */

.nav-inp {
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  width: 120px;
  font-size: 14px;
  background-color: white;
  color: black;
}
.nav-inp-spn{
  background-color: rgb(255, 255, 255);
  /* width: 10px; */
  padding: 5px 0;
  border-radius: 20px;
}
.nav-inp-spn i{
  color: black;
  width: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 1250px) {
  .nav-logo {
    font-size: 1.8rem;
  }

  .nav-end-start > .sd,
  .nav-end-end > .sd {
    display: block;
    font-size: 1.7rem;
  }

  .nav-end-start > .ld,
  .nav-end-end > .ld {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .menu-btn {
    display: block;
    margin-right: 10px;
    color: white;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
  }

  header {
    padding: 20px;
    height: auto;
  }

  header nav {
    margin: 0px -20px;
    padding: 0;
    position: fixed;
    bottom: 100vh;
    height: 100%;
    width: 102%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: var(--mainColor);
    transition: 1s;
    /* margin-top: -50; */
  }

  header .responsive_nav {
    transform: translateY(100vh);
    background-color: black;
    z-index: 2;
  }

  .nav-drop-content {
    width: 300px;
    text-align: start;
    padding: 20px;
  }

  .nav-btn {
    order: -2;
  }

  .nav-end {
    flex: 1;
    text-align: right;
  }

  .nav-close-btn {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    padding: 0;
    color: white;
    background-color: transparent;
    border: none;
  }

  nav a,
  nav div {
    font-size: 1rem;
  }
}


@media only screen and (max-width: 600px) {

  .goc-logo{
  margin-left: 15px;
  }
.goc-logo img{
  width: 190px;
}

  
  header {
    padding: 15px 10px;
  }
  .nav-logo,
  .nav-logo i {
    font-size: 1.4rem;
    margin-left: 6px;
  }

  
  .nav-inp {
    padding: 4px 1px ;

    font-size: 11px;
    width: 85px;
  }
  .nav-inp-spn{
    background-color: rgb(255, 255, 255);
    /* width: 10px; */
    padding: 0 0 3px 0;
    border-radius: 20px;
  }
  .nav-inp-spn i{
    font-size: .7rem;
  }
  
  .menu-btn {

    /* margin-right: 100px; */
    /* padding: 0 20px 0 0; */
    margin-right: 10px;
    font-size: 1.2rem;
  }

  .nav-end-start > .sd,
  .nav-end-end > .sd {
    font-size: 1.1rem;
  }

  .nav-end-start {
    padding-right: 15px;
  }
  .nav-end-end {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 400px) {

    
.goc-logo img{
  width: 160px;
  margin-left: 10px;
}
  
  header {
    padding: 10px 5px;
  }

   header nav {
    width: 104%;

   }

  .nav-logo,
  .nav-logo i {
    font-size: 1rem;
    margin-left: 4px;

  }
  .menu-btn{
    font-size: 1rem;
  }
  
  .nav-inp {
    padding: 4px 1px;
    border-radius: 8px;
    font-size: .7rem;
    width: 85px;
  }

  .nav-end-start > .sd,
  .nav-end-end > .sd {
    font-size: 0.9rem;
  }

  .nav-end-start {
    padding-right: 10px;
  }
  .nav-end-end {
    padding-left: 10px;
    width: auto;
  }
}

@media only screen and (max-width: 4px) {
  header {
    height: auto;
    padding: 10px 10px 20px;
    justify-content: start;
    align-items: center;
  }

  .nav-btn {
    order: -2;
  }

  .nav-end {
    flex: 1;
    text-align: right;
  }

  .nav-logo i {
    font-size: 1.1rem;
  }
  .nav-logo {
    font-size: 1.1rem;
    text-align: start;
  }

  .nav-btn {
    margin-right: 10px;
  }

  .nav-end-start {
    padding-right: 10px;

    line-height: 30px;
  }

  .nav-end-start > .sd {
    display: block;
  }

  .nav-end-start > .ld {
    display: none;
  }

  .nav-end-end {
    display: flex;
    padding-left: 10px;
    align-items: center;
  }
  .nav-end-end > .ld {
    display: none;
  }

  .nav-inp {
    padding: 4px 8px;

    font-size: 11px;
    width: 70px;
  }

  nav a {
    margin-right: -10px;
  }

  .nav-btn {
    display: block;
  }

  header .nav-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 6px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: -60px;
    height: 100%;
    width: 106%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
    background-color: black;
    z-index: 1;
  }

  nav {
    margin-top: -40px;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: x-large;
  }

  nav a,
  nav div {
    font-size: 1.5rem;
  }

  .nav-select {
    width: auto;
    text-align: center;
    padding: 3px 8px;
    font-size: small;
  }
  .nav-end-start i {
    font-size: 0.9rem;
  }
  .nav-end-end i {
    margin-right: 7px;
    font-size: medium;
  }
}
